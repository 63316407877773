import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney } from '../lib/fmt';
import { parseMoney, parseNumber } from '../lib/parser';
import { cn } from '../lib/utils';
import VoidLink from './VoidLink';

export default class ProductGrid extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['order', 'claim']),
    // --
    className: PropTypes.string,
    isEditMode: PropTypes.bool,
    isApplied: PropTypes.bool,
    isCompact: PropTypes.bool,
    providers: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    isCompact: false,
    isApplied: false,
    providers: [],
  };

  render() {
    const { items, isEditMode, isCompact } = this.props;
    const isClaim = this.isClaim();
    const removeClassName = cn((!isEditMode || isCompact) && 'd-none', 'w-40px');
    const checkClassName = cn((isClaim || isEditMode || isCompact || items.length === 0) && 'd-none', 'w-40px');
    const n = items.length;
    const trSummaryClassName = `font-weight-bold ${n > 0 && n % 2 === 0 ? 'table-accent-bg' : ''}`;
    let count = 0;
    let amount = 0;
    items.forEach((item) => {
      const price = parseMoney(item.price);
      const qty = parseNumber(item.qty);
      count += qty || 0;
      amount += price && qty ? price * qty : 0;
    });
    return (
      <table className="Grid table table-sm table-bordered">
        <thead>
          <tr>
            <th className="w-40px">#</th>
            <th className="w-120px">Код</th>
            <th>Наименование</th>
            <th className="w-80px">Кол-во</th>
            <th className="w-120px">Цена</th>
            <th className="w-140px">Стоимость</th>
            {isClaim && !isCompact && <th className="w-140px">Поставщик</th>}
            {isClaim && !isCompact && <th className="w-80px">Дни</th>}
            <th className={checkClassName}></th>
            <th className={removeClassName}></th>
          </tr>
        </thead>
        <tbody>
          {this.renderItems()}
          <tr className={trSummaryClassName}>
            <td colSpan="3">Итого:</td>
            <td>{count}</td>
            <td></td>
            <td>{formatMoney(amount, true, true)}</td>
            {isClaim && !isCompact && <td />}
            {isClaim && !isCompact && <td />}
            <td className={checkClassName}></td>
            <td className={removeClassName}></td>
          </tr>
        </tbody>
      </table>
    );
  }

  // event handlers

  onChange(items) {
    if (this.props.onChange) {
      this.props.onChange(items);
    }
  }

  onDelete(index) {
    const { items } = this.props;
    const newItems = [...items];
    newItems.splice(index, 1);
    this.onChange(newItems);
  }

  onFieldChange(e, index, name) {
    const { items } = this.props;
    const item = items[index];
    const newItems = [...items];
    newItems[index] = { ...item, [name]: e.target.value };
    this.onChange(newItems);
  }

  // render helpers

  renderItems() {
    const { items } = this.props;
    if (items.length === 0) {
      return this.renderItem();
    }
    return items.map((item, index) => this.renderItem(item, index));
  }

  renderItem(item, index) {
    const { isEditMode, providers, isCompact, isApplied } = this.props;
    const isClaim = this.isClaim();
    const removeClassName = cn((!isEditMode || isCompact) && 'd-none');
    const checkClassName = cn((isClaim || isEditMode || isCompact) && 'd-none');
    if (!item) {
      return (
        <tr>
          <td>&nbsp;</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {isClaim && !isCompact && <td></td>}
          {isClaim && !isCompact && <td></td>}
          <td className={removeClassName}></td>
        </tr>
      );
    }
    const price = parseMoney(item.price);
    const qty = parseNumber(item.qty);
    const amount = price && qty ? price * qty : 0;
    const trClassName = index % 2 === 0 ? 'table-accent-bg' : '';
    return (
      <tr className={trClassName} key={index}>
        <td
          className={cn(
            isClaim && isEditMode ? (item.qty > item.product_stock_qty ? 'table-danger' : 'table-success') : '',
          )}
        >
          {index + 1}
        </td>
        <td>{item.product_sku_producer}</td>
        <td>
          {item.product_name} {isClaim && `(${item.producer_name})`}
        </td>
        <td>
          {isClaim && !isEditMode && !isCompact && !isApplied ? (
            <span
              className={cn('font-weight-bold', item.qty > item.product_stock_qty ? 'text-danger' : 'text-success')}
            >
              {item.qty} / {item.product_stock_qty}
            </span>
          ) : (
            <input
              className="form-control form-control-sm bg-transparent"
              value={item.qty}
              disabled={!isEditMode}
              onChange={(e) => this.onFieldChange(e, index, 'qty')}
            />
          )}
        </td>
        <td>
          <input
            className="form-control form-control-sm bg-transparent"
            value={item.price}
            disabled={!isEditMode}
            onChange={(e) => this.onFieldChange(e, index, 'price')}
          />
        </td>
        <td>{formatMoney(amount, true, true)}</td>
        {isClaim && !isCompact && (
          <td>
            <select
              className="form-control form-control-sm bg-transparent"
              value={item.contractor_id}
              disabled={!isEditMode}
              onChange={(e) => this.onFieldChange(e, index, 'contractor_id')}
            >
              {providers.map((provider) => (
                <option key={provider.value} value={provider.value}>
                  {provider.title}
                </option>
              ))}
            </select>
          </td>
        )}
        {isClaim && !isCompact && (
          <td>
            <input
              className="form-control form-control-sm bg-transparent"
              value={item.delivery_days}
              disabled={!isEditMode}
              onChange={(e) => this.onFieldChange(e, index, 'delivery_days')}
            />
          </td>
        )}
        <td className={checkClassName}>
          {!isClaim && <i className={cn('fas fa-check', !item.is_checked && 'opacity-10')} />}
        </td>
        <td className={removeClassName}>
          <VoidLink onClick={() => this.onDelete(index)}>
            <i className="fas fa-times text-danger" />
          </VoidLink>
        </td>
      </tr>
    );
  }

  // other helpers

  isOrder() {
    const { type } = this.props;
    return type === 'order';
  }

  isClaim() {
    const { type } = this.props;
    return type === 'claim';
  }
}
